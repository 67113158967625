import COLORS from '@utils/colors';
import css from 'styled-jsx/css';

const styles = css`
  .plan-card {
    height: 100%;
    position: relative;
  }

  .plan-item {
    min-width: 300px;
    z-index: 1;
    position: relative;
    border: 1px solid ${COLORS.black200};
    box-shadow:
      0px 6px 12px -6px rgba(24, 39, 75, 0.12),
      0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    border-radius: 22px;
    display: block;
    width: 100%;
    height: 100%;
    text-align: left;
    overflow: hidden;
    background-color: ${COLORS.white};
    &.selective {
      cursor: pointer;
    }
    &.selected,
    &.recommend {
      border: 4px solid ${COLORS.primary};
    }
    .recommend-label {
      background: linear-gradient(
        93.83deg,
        ${COLORS.primary},
        ${COLORS.purple600}
      );
      padding: 4px 0;
      text-align: center;
    }
    .plan-content {
      padding: 0 12px;
      display: flex;
      align-items: baseline;
      .plan-check-icon {
        width: 24px;
        height: 24px;
      }
      .disabled-label {
        background: ${COLORS.black200};
        border-radius: 100px;
        padding: 4px 8px;
      }
    }
  }
`;

export default styles;
