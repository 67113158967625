import React from 'react';
import IconCheckBlank from '@assets/icon-check-blank.svg';
import IconCheck from '@assets/icon-check-new.svg';
import { TypoXSBold, TypoXXSBold } from '@atoms/Typos';
import PlanInfo from '@components/molecules/PlanInfo';
import useLocation from '@hooks/useLocation';
import { NeighborPlan as NeighborPlanType } from '@services/hafh/types/generated';
import { useIsDiscount } from '@utils/hooks';
import classNames from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import styles from './css';

type Props = {
  changeablePlan?: any;
  disabledLabelKey?: string;
  isSelected?: boolean;
  onSelectPlan?: (
    neighborPlan: NeighborPlanType | null,
    promotionPlan: NeighborPlanType | null
  ) => void;
  plan: NeighborPlanType;
  showFirstMonthDiscount?: boolean;
  showLabel?: boolean;
  showReferralCodeBonus?: boolean;
};

const NeighborPlan = ({
  changeablePlan,
  disabledLabelKey,
  isSelected,
  onSelectPlan,
  plan,
  showFirstMonthDiscount,
  showLabel = true,
  showReferralCodeBonus = false,
}: Props) => {
  const { t } = useTranslation('neighbor-plan');

  const isDiscount = useIsDiscount();
  const { isJapan } = useLocation();

  const onClick = () => {
    if (onSelectPlan && !disabledLabelKey) {
      onSelectPlan(plan, null);
    }
  };

  return (
    <div className="plan-card">
      <div
        className={classNames('plan-item', {
          selected: isSelected,
          selective: onSelectPlan && !disabledLabelKey,
        })}
        data-testid={`neighbor-plan-${plan.code}`}
        onClick={onClick}
        onKeyDown={onClick}
        role="button"
        tabIndex={0}
      >
        {!isJapan && plan.recommend && (
          <div className="recommend-label">
            <TypoXSBold color="white" text={t('recommend.others')} />
          </div>
        )}
        <div
          className={classNames('plan-content', {
            recommend: plan.recommend,
          })}
        >
          <div className="plan-select">
            {disabledLabelKey && (
              <div className="disabled-label">
                <TypoXXSBold
                  color="black700"
                  text={t(`disabledLabel.${disabledLabelKey}`)}
                />
              </div>
            )}
            {!disabledLabelKey &&
              onSelectPlan &&
              (isSelected ? (
                <div className="plan-check-icon">
                  <IconCheck />
                </div>
              ) : (
                <IconCheckBlank />
              ))}
          </div>
          <PlanInfo
            changeablePlan={changeablePlan}
            plan={plan}
            showDiscount={showFirstMonthDiscount && isDiscount}
            showLabel={showLabel}
            showReferralCodeBonus={showReferralCodeBonus}
          />
        </div>
      </div>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default NeighborPlan;
