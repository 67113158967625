import { Neighbor, Reservation } from '@services/hafh/types/generated';
import {
  ALPHABET_AND_SPACE_REGEX,
  FREE_PLAN_KIND,
  KATAKANA_AND_SPACE_REGEX,
  NEW_PLAN_CODES,
} from '@utils/constants';
import { getNeighborOnFreePlan } from '@utils/neighbor';
import routes from '@utils/routes';
import { canUseSplitReservationFeature, isEmpty } from '@utils/utils';
import moment from 'moment';

// if neighbor is on the free plan, they cannot edit their profile or credit card
const getFreePlanRestricted = (neighbor: Neighbor, pathname: string) =>
  getNeighborOnFreePlan(neighbor) &&
  (pathname === routes.profileEdit || pathname === routes.creditCardEdit);

// if neighbor is on a paid plan and payment_gateway is not set (missing credit card)
// they must add a credit card before doing anything else
const getMissingCreditCardRestricted = (neighbor: Neighbor, pathname: string) =>
  neighbor.neighbor_plans?.[0].kind !== FREE_PLAN_KIND &&
  !neighbor.payment_gateway &&
  pathname !== routes.profile &&
  pathname !== routes.profileEdit &&
  pathname !== routes.creditCardEdit;

export const getHasUpcomingBooking = (neighbor: Neighbor) => {
  let stayCountFromNextPlan = 0;

  neighbor.neighbor_plans?.slice(1).forEach((neighborPlan) => {
    stayCountFromNextPlan += neighborPlan.max_nights - neighborPlan.nights;
  });

  return stayCountFromNextPlan > 0;
};

export const getPaymentStatusInvalid = (neighbor: Neighbor) =>
  neighbor.payment_status !== 'successed' &&
  neighbor.payment_status !== 'not_provided';

const PROFILE_DELETE_ROUTES = [
  routes.profileDelete,
  routes.profileDeleteConfirm,
  routes.profileDeleteComplete,
];

export const getPageAccessRestricted = (
  neighbor: Neighbor,
  pathname: string
) => {
  if (getFreePlanRestricted(neighbor, pathname)) {
    // no error message, just redirect
    return true;
  }

  if (getMissingCreditCardRestricted(neighbor, pathname)) {
    return 'error.missingCreditCard';
  }

  if (PROFILE_DELETE_ROUTES.includes(pathname)) {
    if (getHasUpcomingBooking(neighbor)) {
      return 'error.unsubscribe.booking';
    }

    if (getPaymentStatusInvalid(neighbor)) {
      return 'error.unsubscribe.payment';
    }
  }

  // no restriction means neighbor can access the page
  return false;
};

/**
 * Check whether profile form should be shown before proceeding to
 * reservation request confirm page or not
 * For debug the profile form, you can set every time return true
 */
export const shouldUpdateNeighborProfileForReservation = (neighbor: Neighbor) =>
  !(
    neighbor.first_name &&
    neighbor.last_name &&
    ALPHABET_AND_SPACE_REGEX.test(neighbor.first_name) &&
    ALPHABET_AND_SPACE_REGEX.test(neighbor.last_name) &&
    neighbor.gender !== 'unselected' &&
    neighbor.address &&
    neighbor.country &&
    (neighbor.country.code !== 'jp' ||
      (neighbor.first_name_kana &&
        neighbor.last_name_kana &&
        KATAKANA_AND_SPACE_REGEX.test(neighbor.first_name_kana) &&
        KATAKANA_AND_SPACE_REGEX.test(neighbor.last_name_kana)))
  );

export const validateAcceptSplitReservation = (
  authUser: any,
  neighbor: Neighbor,
  reservation: Reservation,
  asPath: string
) => {
  if (
    isEmpty(neighbor) ||
    reservation.split_reservation_status?.is_invite_accepted
  ) {
    return null;
  }

  if (
    !reservation.representative &&
    neighbor.coin < Number(reservation.split_reservation_total_coin)
  ) {
    return { key: 'lackOfCoin', link: routes.upgradePlan };
  }

  if (!canUseSplitReservationFeature(neighbor)) {
    return { key: 'notEligiblePlan', link: routes.upgradePlan };
  }

  if (neighbor.payment_status !== 'successed') {
    return { key: 'paymentError', link: routes.concierge };
  }

  if (
    neighbor.status === 'to_be_unsubscribed' &&
    moment(reservation.check_out_date).isAfter(
      neighbor.neighbor_plans?.[1].start_date
    )
  ) {
    return { key: 'unsubscribed', link: routes.profile };
  }

  if (shouldUpdateNeighborProfileForReservation(neighbor)) {
    return {
      key: 'noProfile',
      link: `${routes.profileFormForReservation}?redirect_url=${asPath}`,
    };
  }

  if (!neighbor.phone_number || !authUser.phoneNumber) {
    return {
      key: 'noPhoneNumber',
      link: `${routes.phoneNumberNew}?redirect_url=${asPath}`,
    };
  }

  return null;
};

export const isNewNeighborPlanCodeForJapan = (code = '') =>
  NEW_PLAN_CODES.includes(code);

export const isNewNeighborPlanForJapan = (neighbor: Neighbor) =>
  isNewNeighborPlanCodeForJapan(neighbor.neighbor_plans?.[0].code);
