import React from 'react';
import IconCoin from '@assets/icon-coin-new.svg';
import Label from '@atoms/Label';
import PlanPrice from '@atoms/PlanPrice';
import SignUpPlanPrice from '@atoms/SignUpPlanPrice';
import {
  TypoLBold,
  TypoSBold,
  TypoXS,
  TypoXSBold,
  TypoXXS,
} from '@atoms/Typos';
import { useIsSignupDiscountCampaignActive } from '@hooks/useCampaign';
import { NeighborPlan } from '@services/hafh/types/generated';
import { BASIC_CODE, NEW_STANDARD_CODE } from '@utils/constants';
import { formatDiscountRate } from '@utils/utils';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import styles from './css';

type PlanInfoProps = {
  changeablePlan?: any;
  plan: NeighborPlan;
  showDiscount?: boolean;
  showLabel?: boolean;
  showReferralCodeBonus?: boolean;
};

const planLabel: { [key: string]: string } = {
  [BASIC_CODE]: '',
  [NEW_STANDARD_CODE]: '継続キャンペーン対象',
};

const PlanInfo = ({
  changeablePlan,
  plan,
  showDiscount,
  showLabel = true,
  showReferralCodeBonus = false,
}: PlanInfoProps) => {
  const signupPlanDiscount =
    showDiscount && plan.first_month_price < plan.monthly_price;
  const upgradePlanDiscount = changeablePlan && changeablePlan.discount;
  const noDiscount = !signupPlanDiscount && !upgradePlanDiscount;

  const isRegisterDiscountCampaignActive = useIsSignupDiscountCampaignActive();

  const { t } = useTranslation('neighbor-plan');
  const discountRate = formatDiscountRate(
    changeablePlan
      ? 1 - changeablePlan.first_month_price / changeablePlan.monthly_plan_price
      : plan.discount?.rate
  );

  return (
    <div className="plan-info">
      {isRegisterDiscountCampaignActive &&
        planLabel[plan.code] &&
        showLabel && (
          <div className="plan-labels">
            <Label color="green500" text={planLabel[plan.code]} />
            {signupPlanDiscount && (
              <Label
                color="pink500"
                text={t('firstMonthDiscount', {
                  rate: discountRate,
                })}
              />
            )}
          </div>
        )}
      <div className="plan-promo">
        <TypoLBold text={plan.name} />
      </div>
      {signupPlanDiscount && <SignUpPlanPrice plan={plan} />}
      {upgradePlanDiscount && (
        <SignUpPlanPrice changeablePlan={changeablePlan} plan={plan} />
      )}
      {noDiscount && <PlanPrice plan={plan} />}
      <div className="coin-text">
        <div className="coin-icon-cont">
          <IconCoin />
        </div>
        <TypoXSBold color="black900">
          <Trans
            i18nKey="neighbor-plan:coinPerMonth"
            values={{
              coin: plan.coin,
            }}
          />
        </TypoXSBold>
      </div>
      {showReferralCodeBonus && plan.referral_bonus_coin && (
        <div className="coin-text">
          <div className="coin-icon-cont">
            <IconCoin />
          </div>
          <TypoXXS color="black600">
            <Trans
              components={{
                black: <TypoSBold color="black700" />,
                sky: <TypoSBold color="primary" />,
              }}
              i18nKey="neighbor-plan:referralCoin"
              values={{
                coin: plan.referral_bonus_coin,
              }}
            />
          </TypoXXS>
        </div>
      )}
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default PlanInfo;
