/* eslint-disable */
import classNames from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import Button from '@atoms/Button';

import { useDisablePageScrollWhenShowModal } from '@utils/hooks';

import styles from './css';

type Props = {
  show: boolean;
  fullScreen?: boolean;
  headerText?: string;
  showHeaderBorder?: boolean;
  children: any;
  primaryButtonLabel?: string;
  primaryButtonDisabled?: boolean;
  primaryButtonClass?: string;
  primaryButtonSize?: string;
  closeButtonSize?: string;
  onClickPrimaryButton?: () => void;
  secondaryButtonLabel?: string;
  secondaryButtonSize?: string;
  onClickSecondaryButton?: () => void;
  onClickButton?: () => void;
  closeLabel?: string;
  buttonAlign?: 'vertical' | 'horizontal';
  modalType?: 'warning';
  showCloseLabel?: boolean;
};

const Dialog = ({
  show,
  fullScreen,
  headerText,
  showHeaderBorder = false,
  buttonAlign = 'horizontal',
  children,
  // Primary button
  primaryButtonLabel,
  primaryButtonDisabled = false,
  primaryButtonClass,
  primaryButtonSize,
  onClickPrimaryButton,
  // Secondary button
  secondaryButtonLabel,
  secondaryButtonSize,
  onClickSecondaryButton,
  // Close button
  closeButtonSize,
  onClickButton,
  closeLabel,
  showCloseLabel = true,
  modalType,
}: Props) => {
  const { t } = useTranslation('common');
  useDisablePageScrollWhenShowModal(show);

  const withPrimaryButton = primaryButtonLabel && onClickPrimaryButton;
  const withSecondaryButton = secondaryButtonLabel && onClickSecondaryButton;

  return show ? (
    <div
      className={classNames('dialog-overlay', {
        active: show,
        fullScreen,
      })}
    >
      <div className={`dialog-container ${modalType ? modalType : ''}`}>
        {headerText &&
          (fullScreen ? (
            <h2 className={classNames('dialog-title')}>{headerText}</h2>
          ) : (
            <h3
              className={classNames('dialog-header', {
                'with-border': showHeaderBorder,
              })}
            >
              {headerText}
            </h3>
          ))}
        <div className="dialog-content">{children}</div>
        <div
          className={classNames('button-container', {
            'with-buttons': withPrimaryButton || withSecondaryButton,
            'buttons-vertical': buttonAlign === 'vertical',
          })}
        >
          {withPrimaryButton && (
            <Button
              customClass={classNames('primary-button', primaryButtonClass)}
              size={primaryButtonSize}
              width="responsive"
              label={primaryButtonLabel}
              onClick={(e) => {
                e.stopPropagation();
                onClickPrimaryButton();
              }}
              disabled={primaryButtonDisabled}
              testId="dialog-primary-button"
            />
          )}
          {withSecondaryButton && (
            <Button
              isSecondary
              customClass="Secondary-button"
              size={secondaryButtonSize}
              width="responsive"
              label={secondaryButtonLabel}
              onClick={(e) => {
                e.stopPropagation();
                onClickSecondaryButton();
              }}
            />
          )}
          {onClickButton && showCloseLabel && (
            <button
              className={classNames('button-link', {
                [`size-${closeButtonSize}`]: closeButtonSize,
              })}
              type="button"
              data-testid="close-dialog"
              onClick={(e) => {
                e.stopPropagation();
                onClickButton();
              }}
            >
              {closeLabel || t('close')}
            </button>
          )}
        </div>
      </div>
      <div
        className="daialog-background"
        aria-hidden
        onClick={onClickButton}
        role="button"
      />

      <style jsx>{styles}</style>
    </div>
  ) : null;
};

export default Dialog;
