import css from 'styled-jsx/css';

const styles = css`
  .label {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 4px;
    padding: 3px 8px;
    border-radius: 100px;
  }
`;

export default styles;
