import React from 'react';
import { TypoMBold, TypoXXLBold } from '@atoms/Typos';
import { NeighborPlan } from '@services/hafh/types/generated';
import Trans from 'next-translate/Trans';

type Props = {
  plan: NeighborPlan;
};

const PlanPrice = ({ plan }: Props) => (
  <TypoMBold>
    <Trans
      components={{
        large: <TypoXXLBold />,
      }}
      i18nKey="neighbor-plan:pricePerMonth"
      values={{
        currencySymbol: plan.currency.symbol,
        price: plan.monthly_price.toLocaleString(),
      }}
    />
  </TypoMBold>
);

export default PlanPrice;
