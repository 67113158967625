import css from 'styled-jsx/css';

const styles = css`
  .signup-price {
    align-items: baseline;
    display: flex;
    gap: 4px;
  }

  .normal-price {
    display: flex;
    gap: 4px;
    align-items: center;
  }
`;

export default styles;
